/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
			// JavaScript to be fired on all pages

				/* ADD JS STYLES */
				$('html').addClass('js');
				/* END ADD JS STYLES */

				/* HEADER SCROLL EFFECT */
				var sticky_navigation_offset_top = 0;
				// Create a true/false variable to manage animations running on EVERY scroll
				var animateHeader = true;

				// Create the function to run on each scroll
				var sticky_navigation = function(){
					var scroll_top = $(window).scrollTop();
						if (scroll_top > sticky_navigation_offset_top) {
						// First let's add a scrolled class to the whole header
						$('header.banner').addClass('scrolled');
						$('#click-menu').addClass('scrolled');
					} else {
						// Restore order to the header
						$('#click-menu').removeClass('scrolled');
						$('header.banner').removeClass('scrolled');
					}
				};
				// Run our function on page load
				sticky_navigation();

				// Run our function on each scroll
				$(window).on('scroll', function() {
					 sticky_navigation();
				});
				/* END HEADER SCROLL EFFECT */


				/** SHOW AND HIDE SEARCH BAR **/
				$('#search-button').click(function() {
					$('#header-main').fadeToggle('fast', function() {
						$('#header-search').fadeToggle('slow');
						// Focus on input for all browsers except IE11. They have a placeholder text bug.
						if ( (window.ActiveXObject) && !("ActiveXObject" in window) ) {
							$('#header-search input[type="text"]').focus();
						}
					});
					return false;
				});
				$('#search-close').click(function() {
					$('#header-search').fadeToggle('fast', function(){
						$('#header-main').fadeToggle('slow');
					});
				});
				// Shake Animation
				function shake(div) {
					var interval = 100;
					var distance = 10;
					var times = 4;
					$(div).css('position','relative');
					for(var iter = 0; iter < (times + 1); iter++){
						$(div).animate({ left:((iter % 2 === 0 ? distance : distance * -1)) }, interval);
					}
					$(div).animate({ left: 0 },interval);
				}
				// Search Submit
				$('#header-search').submit(function(e) {
					var s = $(this).find('input[type="text"]');
					if (!s.val()) {
						e.preventDefault();
						shake($(this).find('input[type="text"]'));
						$(this).find('input[type="text"]').focus();
					}
				});
				/** END SHOW OR HIDE SEARCH BAR **/


				/** ANIMATED ANCHOR LINKS **/
				$('a[href*="#"]:not([href="#"])').click(function(e) {
					if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
						var target = $(this.hash);
						var $this = this;
						target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
						if (target.length) {
							$('html,body').animate({
								scrollTop: (target.offset().top - 70)
							}, 500, function() {
								if(history.pushState) {
									history.pushState(null, null, $this.hash);
								}
								else {
									location.hash = $this.hash;
								}
							});
							e.preventDefault();
						}
					}
				});
				/** END ANIMATED ANCHOR LINKS **/


				/** FULL WIDTH ROWS */
				function fullWidthRows() {
					var wrapperClass = '.container';
					var element = $('.full-width');
					var width = element.closest(wrapperClass).width();
					var spacing = ( $(window).width() - width ) / 2 ;

					element.css({
						'margin-left' : - spacing,
						'margin-right' : - spacing,
						'padding-left' : 0,
						'padding-right' : 0,
					});
				}
				$(window).load( fullWidthRows );
				$(window).resize( fullWidthRows );
				/** END FULL WIDTH ROWS */


				/* NUMBER TICKER UPON VIEW */
				var numberTickers = $('.ticker');
				if ( numberTickers.length ) {
					numberTickers.counterUp({
						time: 700
					});
				}
				/* END NUMBER TICKER UPON VIEW */


				/* SLICK CAROUSEL */
				$('.carousel-wrapper .row').slick({
					infinite: true,
					centerPadding: '0px',
					slidesToShow: 3,
					slidesToScroll: 3,
					swipeToSlide: true,
					dots: true,
					// mobileFirst: true,
					responsive: [
						{
							breakpoint: 992,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 2,
							}
						},
						{
							breakpoint: 640,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1
							}
						},
					]
				});
				/* END SLICK CAROUSEL */

			},
			finalize: function() {
			// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
			// JavaScript to be fired on the home page
			},
			finalize: function() {
			// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'latest_news': {
			init: function() {
				// JavaScript to be fired on the about us page

				/* SHOW HIDE FILTERS */
				$( '#filter-button .icon-angle-circled-down' ).addClass('closed');
				$( '#filter-button' ).click(function() {
					$( '#filter-button .icon-angle-circled-down' ).toggleClass('closed');
					$( '#post-filters' ).slideToggle( 'fast' );
				});
				/* END SHOW HIDE FILTERS */

			}
		},
		// About us page, note the change from about-us to about_us.
		'category': {
			init: function() {
				// JavaScript to be fired on the about us page

				/* SHOW HIDE FILTERS */
				$( '#filter-button .icon-angle-circled-down' ).addClass('closed');
				$( '#filter-button' ).click(function() {
					$( '#filter-button .icon-angle-circled-down' ).toggleClass('closed');
					$( '#post-filters' ).slideToggle( 'fast' );
				});
				/* END SHOW HIDE FILTERS */

			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
	fire: function(func, funcname, args) {
		var fire;
		var namespace = Sage;
		funcname = (funcname === undefined) ? 'init' : funcname;
		fire = func !== '';
		fire = fire && namespace[func];
		fire = fire && typeof namespace[func][funcname] === 'function';

		if (fire) {
		namespace[func][funcname](args);
		}
	},
	loadEvents: function() {
		// Fire common init JS
		UTIL.fire('common');

		// Fire page-specific init JS, and then finalize JS
		$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
		UTIL.fire(classnm);
		UTIL.fire(classnm, 'finalize');
		});

		// Fire common finalize JS
		UTIL.fire('common', 'finalize');
	}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
